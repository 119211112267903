<template>
  <div>
    <el-card>
      <el-row :gutter="20" class="left">
        <el-col :span="5">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back">
            退款详情
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="warning">
        <p>同意退款,退款金额:￥0.01</p>
      </div>
      <div class="header"><div class="title">用户信息</div></div>
      <el-form label-width="120px" class="left margin_left">
        <el-form-item label="用户ID:"><div>1105</div></el-form-item>
        <el-form-item label="姓名:"><div>王先生</div></el-form-item>
        <el-form-item label="手机号:"><div>18888888888</div></el-form-item>
        <el-form-item label="车费详情:"><div>出租车 全程58.08m 车费￥5</div></el-form-item>
        <el-form-item label="服务地址:"><div>江苏省苏州市吴中区吴中大道 1188 号</div></el-form-item>
      </el-form>
      <div class="header"><div class="title">技师信息</div></div>
      <el-form label-width="120px" class="left margin_left">
        <el-form-item label="技师:"><div>初级技师A</div></el-form-item>
        <el-form-item label="技师头像:">
          <el-image
            style="width: 100px; height: 100px"
            src="https://img1.baidu.com/it/u=3642236042,906489063&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=500"
            fit="fill">
          </el-image>
        </el-form-item>
      </el-form>
      <div class="header"><div class="title">订单信息</div></div>
      <el-form label-width="120px" class="left margin_left">
        <el-form-item label="付款订单号:"><div>20220729181649042900000429</div></el-form-item>
        <el-form-item label="退款订单号:"><div>20220729182806093500000935</div></el-form-item>
        <el-form-item label="微信退款订单号:"><div>148282068220220729182850</div></el-form-item>
        <el-form-item label="提交时间:"><div>2022-07-29 18:28:06</div></el-form-item>
        <el-form-item label="审核时间:"><div>2022-07-29 18:28:50</div></el-form-item>
        <el-form-item label="退款原因:"><div>未服务</div></el-form-item>
      </el-form>
      <div class="header"><div class="title">退款服务</div></div>
      <el-table
        class="margin_left"
        :data="refundServiceTable"
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column label="服务图片">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.url"
              fit="fill">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="serviceName" label="服务名称"></el-table-column>
        <el-table-column prop="serviceCount" label="服务数量"></el-table-column>
      </el-table>
      
      <div class="left margin_left">
        <div class="margin_tb">合计数量:1</div>
        <el-button class="margin_tb" type="primary" @click="back">返回</el-button>
      </div>
      
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      refundServiceTable:[
        {
          url:'https://img1.baidu.com/it/u=3642236042,906489063&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=500',
          serviceName:'经络推拿',
          serviceCount:'1'
        }
      ]
    }
  },
  created() {
  },
  methods: {
    back(){
      this.$router.go(-1)
    }
  },
}
</script>

<style lang="scss" scoped>
@import './Refund_detail.vue.scss';
</style>